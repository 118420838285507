.price-card {
    flex-shrink: 0;
    border-radius: 16px;
    background: linear-gradient(0deg, #FF5B0408 0%, #FF5B0405 100%), rgba(9, 11, 10, 0.00);
}

.price-card-text {
    align-self: stretch;
    color: #FFF;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

