.country-info-container {
    font-family: sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.country-dropdown-container {
    margin-bottom: 20px;
}

.country-dropdown-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.country-details {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    background-color: #000000;
}

.country-details h2 {
    margin-top: 0;
}

.no-info {
    color: red;
}

/* Style the react-country-region-selector dropdown */
.country-dropdown {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Important to include padding and border in the element's total width */
}

.country-select {
    width: 100%; /* Adjust as needed */
    padding: 8px;
    border: 2px solid #FF5B0433;
    border-radius: 8px;
    box-sizing: border-box; /* Important to include padding and border in the element's total width */
    font-size: 16px;
    margin-top: 5px;
    color: #ffffff;
    background-color: #00000000;
    appearance: none;
    /* Remove default arrow */
    -webkit-appearance: none;
    /* For Safari */
    -moz-appearance: none;
    /* For Firefox */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFFFFF'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 8px top 50%;
    background-size: 16px;
    /* Add some space for the arrow */
    padding-right: 24px;
  }
  
  /* Optional: Style the options in the dropdown */
  .country-select option {
    padding: 8px;
    background-color: #0a0a0a;
    border: none;
  }
  
  /* Optional: Style the select on focus */
  .country-select:focus {
    outline: none;
    border-color: #FF5B04aa; /* Example: Highlight on focus */
  }